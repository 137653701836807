import { render } from "./DashboardBranchManager.vue?vue&type=template&id=1b9512fc&scoped=true"
import script from "./DashboardBranchManager.vue?vue&type=script&lang=js"
export * from "./DashboardBranchManager.vue?vue&type=script&lang=js"

import "./DashboardBranchManager.vue?vue&type=style&index=0&id=1b9512fc&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-1b9512fc"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1b9512fc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1b9512fc', script)) {
    api.reload('1b9512fc', script)
  }
  
  module.hot.accept("./DashboardBranchManager.vue?vue&type=template&id=1b9512fc&scoped=true", () => {
    api.rerender('1b9512fc', render)
  })

}

script.__file = "src/components/dashboard/DashboardBranchManager.vue"

export default script