<template>
  <div class="py-4">
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">
      <router-link to="/dashboard"> Dashboard </router-link>
      <router-link
        class="text-sm"
        :to="`/dashboard/business/${getBusinessId()}`"
        >/ {{ business.business_name }}
      </router-link>
      <span class="text-sm ml-1"> / {{ location.location_name }} </span>
    </h1>

    <div class="border-2 border-gray-200">
      <div class="py-4 bg-gray-200 text-center text-3xl">
        {{ getStringMonth[currentMonth] }}
      </div>
      <div class="text-center py-7">
        <div class="text-1xl">Total Messages Sent</div>
        <div class="text-2xl text-color font-semibold mt-2">
          {{ location.location_messages_sent || 0 }}
        </div>
      </div>
      <div class="text-center py-7">
        <div class="text-1xl">Total Messages Received</div>
        <div class="text-2xl text-color font-semibold mt-2">
          {{ location.location_messages_received || 0 }}
        </div>
      </div>
    </div>

    <div class="font-semibold inline-flex w-full mt-4">
      <div class="w-1/4">Name</div>
      <div class="w-1/3 text-center">Review Text Sent This Week</div>
      <div class="w-1/3 text-center">Review Text Sent This Month</div>
    </div>

    <div class="inline-flex w-full my-2" v-for="user in users" :key="user.uid">
      <div class="w-1/4 ">
        <a class="text-blue-400 capitalize cursor-pointer">
          {{ user.display_name }}
        </a>
        <span class="text-xs block capitalize">
          {{ trimUserRole(user.role) }}
        </span>
      </div>
      <div class="w-1/3 text-center">{{ user.review_texts_by_week || 0 }}</div>
      <div class="w-1/3 text-center">{{ user.review_texts_by_month || 0 }}</div>
    </div>
  </div>
</template>
<script>
const firebase = require("../../firebaseConfig");

export default {
  name: "DashboardBranchManager",
  data: () => ({
    business: {},
    location: {},
    messagesCount: 0,
    users: [],
    user: {},
    currentMonth: new Date().getMonth(),
    getStringMonth: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  async mounted() {
    this.user = await this.getUserInfo();
    this.getBusiness();
    this.getLocation();
    this.getUsers();
  },
  methods: {
    trimUserRole(role) {
      return role.replace("-", " ");
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                uid: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    getBusiness() {
      firebase.db
        .collection("businesses")
        .doc(this.getBusinessId())
        .onSnapshot((doc) => {
          this.business = {
            id: doc.id,
            ...doc.data(),
          };
        });
    },
    getBusinessId() {
      return this.$route.params.business_id
        ? this.$route.params.business_id
        : this.user.business_id;
    },
    getLocationId() {
      return this.$route.params.location_id
        ? this.$route.params.location_id
        : this.user.location_id;
    },
    getLocation() {
      firebase.db
        .collection("businesses")
        .doc(this.getBusinessId())
        .collection("locations")
        .doc(this.getLocationId())
        .onSnapshot({ includeMetadataChanges: true }, (doc) => {
          this.location = {
            id: doc.id,
            ...doc.data(),
          };
          console.info("locations are", this.location);
        });
    },
    getUsers() {
      firebase.db
        .collection("users")
        .where("location_id", "==", this.getLocationId())
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.users = [];
          docs.forEach((doc) => {
            this.users.push({
              uid: doc.id,
              ...doc.data(),
            });
          });
          console.info("users are", this.users);
        });
    },
  },
};
</script>
<style scoped>
.text-color {
  color: #f78201;
}
</style>
